<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="EmptyStates">
                    <template v-slot:description>
                        <div>
                            <div>import { HbEmptyState } from 'hummingbird-aviary';</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Basic Empty State Example + Code" class="mt-4 mb-6">

            <div class="pa-4">
                <HbEmptyState message="Please select a Property to continue." />
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbEmptyState message="Please select a Property to continue." />
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Empty State with Header and One Button Example + Code" class="mt-4 mb-6">

            <div class="pa-4">
                <HbEmptyState
                    header="Sample Header"
                    message="Please select a Property to continue."
                    btn-txt="Add Something"
                    @click="success = true"
                />
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbEmptyState
    header="Sample Header"
    message="Please select a Property to continue."
    btn-txt="Add Something"
    @click="success = true"
/>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Empty State with Multiple Buttons Using Buttons Slot Example + Code" class="mt-4 mb-6">

            <div class="pa-4">
                <hb-empty-state message="Please select a Property to continue.">
                    <template v-slot:buttons>
                        <hb-btn prepend-icon="mdi-plus" @click="doSomething()">First</hb-btn>
                        <hb-btn prepend-icon="mdi-plus" @click="doSomething()">Second</hb-btn>
                        <hb-btn prepend-icon="mdi-plus" @click="doSomething()">Third</hb-btn>
                    </template>
                </hb-empty-state>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-empty-state message="Please select a Property to continue.">
    &lt;template v-slot:buttons>
        &lt;hb-btn prepend-icon="mdi-plus" @click="doSomething()">First&lt;/hb-btn>
        &lt;hb-btn prepend-icon="mdi-plus" @click="doSomething()">Second&lt;/hb-btn>
        &lt;hb-btn prepend-icon="mdi-plus" @click="doSomething()">Third&lt;/hb-btn>
    &lt;/template>
&lt;/hb-empty-state>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            description="The '@click' event was initiated."
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemEmptyStates",
        data: function() {
            return {
                success: false,
                propHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'message', type: 'string', default: 'undefined', description: 'Sets the main message for the empty state.' },
                    { name: 'btn-text', type: 'string', default: 'undefined', description: 'Sets the text for the optional button. If this prop is not set then the button will not show.' },
                    { name: 'btn-color', type: 'string', default: 'undefined', description: 'Sets the button color. The only accepted options here are the same naming conventions as "HbBtn" (i.e. primary, secondary, destructive).' },
                    { name: 'show-btn', type: 'boolean', default: 'true', description: 'Change this to false if you want to manually or programatically turn off the button. This only works for the one button configuration using "btn-text" and/or "btn-color" props. This does not work when using the "buttons" slot.' },
                    { name: 'disabled', type: 'boolean', default: 'false', description: 'Change this to true to disable the button.' },
                    { name: 'header', type: 'string', default: 'undefined', description: 'Sets the optional header text if needed.' },
                    { name: 'background-color', type: 'string', default: 'undefined', description: 'Overrides the default background color.' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'default', description: 'This slot is for when you need more complex vue/html for the description/message. This should rarely be used, but it is available for extra optionality.' },
                    { name: 'buttons', description: 'This slot is for placing buttons (usually HbBtn) when you need more than the default one button. Simply place <HbBtn /> one after the other with no additional padding or margin added. The component will auto add the correct spacing between buttons if you use the correct HbBtn components as your buttons. Using this slot will disable the button set by the "btn-text" prop. So do not use these together. Use one or the other.' }
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@click', description: 'Event for when the button is clicked.' },
                ]
            };
        }
    }
</script>

<style scoped>

</style>